import { Store } from '@ngxs/store';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import {
  Component,
  //  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CSSService, IsthemingService } from './istheming.service';
import { WidgetsRegistryService } from '@ic-builder/widgets-registry';
import { Iscontextoverlay2Component } from './iscontextoverlay2/iscontextoverlay2.component';
import { WidgetsLoaderService } from '@ic-builder/widgets-loader';
import { IIsComponent } from './iis-component.interface';
import { LoadComponent } from './base.actions';
import produce from 'immer';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import { cloneDeep, dissoc } from 'lodash/fp';
import { debounceTime } from 'rxjs/operators';
import { FormGroupListItem, IsFormGroup } from './formgroupdef.model';
import { db } from './db';
import Dexie from 'dexie';
import { importDB, exportDB } from 'dexie-export-import'
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { flattenTree } from '@ic-builder/utils';

export interface isDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  width?: number | string;
  height?: number | string;
}

export interface DialogParams {
  id?: number;
  name?: string;
  childid?: number;
  isparent?: any;
  origin?: number;
  event?: Event;
  info?: unknown;
  data?: unknown;
  loadas?: number;
  outlet?: number;
  onclose?: unknown;
  oncancel?: unknown;
}

export interface MenuParams {
  id: number;
  childid: number;
  origin?: number;
  event?: Event;
  info?: unknown;
  data?: unknown;
  loadas?: number;
  outlet?: number;
  position?:any;
  isparent?:any;
}

export const ISDEFAULT_CONFIG: isDialogConfig = {
  hasBackdrop: false,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
};

export const ISMOBILE_CONFIG: isDialogConfig = {
  hasBackdrop: false,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
  width: '100%',
  height: '100%',
};

export class EventMap {
  name: string;
  eventtype: string;
  action: Function;
  currentevent: Event | null = null;
  overlay: Overlay;
  registry: WidgetsRegistryService;
  specific = '';
  selectall = '';
  self: any;
  id: number;
  parentid: number;
  docElement: any;

  function: Function = (ev:Event) => {
    try {
      this.currentevent = ev;
      return this.action();
    } catch (err) {
      this.store?.dispatch({
        type: '[AppError]',
        payload: {
          code: 1000,
          source: 'function : ' + this.name + this.id.toString(),
          message: err,
          level: 'error',
        },
      });
    }
  };

  constructor(
    name: string,
    eventtype: string,
    action: Function,
    id: number,
    overlay: Overlay,
    registry: WidgetsRegistryService,
    parentid: number,
    specific?: string,
    selectall?: string,
    private comp?: ComponentsService,
    private store?: Store,
    private renderer?: Renderer2
  ) {
    this.name = name;
    this.eventtype = eventtype;
    this.action = action;
    this.id = id;
    this.registry = registry;

    this.overlay = overlay;
    this.parentid = parentid;

    if (specific) {
      this.specific = specific;
    }
    if (selectall) {
      this.selectall = selectall;
    }
  }

  opendia = (id:number, outlet:number, loadas:number, left?:number, top?:number) => {
    this.currentevent?.preventDefault();
    //let widget = this.compService.widgets.get(event.data.cellid);
    //console.log('cell',widget);

    this.store?.dispatch(
      new LoadComponent({
        id: id,
        name: 'popupheader',
        outlet: outlet,
        loadas: loadas,
        renderloadasimmediate: true,
        contextid: 100,
        data: {
          top: top ? top : this.currentevent?['clientY']:0,
          left: left ? left : this.currentevent?['clientX']:0,
          el: this.parentid,
          type: this.eventtype,
          fixed: top && left ? true : false,
        },
      })
    );
  };

  private getOverlayConfig(config: isDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      width: config.width,
      height: config.height,
    });

    return overlayConfig;
  }

  opendialog1 = (params: DialogParams) => {
    if (params.event) {
      this.currentevent = params.event;
    }

    this.currentevent?.stopPropagation();
    this.currentevent?.preventDefault();

    const overlayRef = this.comp?.isMobile()
      ? this.overlay.create(this.getOverlayConfig({ ...ISMOBILE_CONFIG }))
      : this.overlay.create(this.getOverlayConfig({ ...ISDEFAULT_CONFIG }));

    const contextOverlay = new ComponentPortal(
      <ComponentType<Iscontextoverlay2Component>>this.registry.getWidgetType('iscontext2overlay')
    );

    overlayRef.backdropClick().subscribe(() => {
      //overlayRef.detach();
      console.log('backdrop click');
    });

    const compRef = overlayRef.attach(contextOverlay);
    compRef.instance.id = params.id;
    compRef.instance.overlayRef = overlayRef;

    compRef.instance.childid = params.childid ? params.childid:params.id + 1;
    if (params.data) {
      compRef.instance['data'] = params.data;
    }
    if (params.isparent) {
      compRef.instance['isparent'] = params.isparent;
    }
    if (params.loadas) {
      compRef.instance['loadas'] = params.loadas;
    }
    if (params.outlet) {
      compRef.instance['outlet'] = params.outlet;
    }
    if (params.onclose) {
      compRef.instance['onclose'] = params.onclose;
    }
    if (params.oncancel) {
      compRef.instance['oncancel'] = params.oncancel;
    }
    compRef.instance.contextRef = overlayRef;
    if (origin) {
      compRef.instance.origin = params.origin;
    }
    if (params.info) {
      compRef.instance.info = params.info;
    }

    compRef.instance.parentElement = window;

    if (params.data) {
      compRef.instance.setData(params.data);
    }
    compRef.changeDetectorRef.detectChanges();
    return compRef.instance;
  };

  opendialog = (
    id: number,
    childid: number,
    origin?: number,
    event?: Event,
    info?: unknown,
    data?: unknown,
    loadas?: number,
    outlet?: number,
    onclose?: unknown,
    oncancel?: unknown,
    position?: any,
  ) => {
    if (event) {
      this.currentevent = event;
    }

    this.currentevent?.stopPropagation();
    this.currentevent?.preventDefault();

    const overlayRef = this.comp?.isMobile()
      ? this.overlay.create(this.getOverlayConfig({ ...ISMOBILE_CONFIG }))
      : this.overlay.create(this.getOverlayConfig({ ...ISDEFAULT_CONFIG }));

    const contextOverlay = new ComponentPortal(
      <ComponentType<Iscontextoverlay2Component>>(
        this.registry.getWidgetType('iscontext2overlay')
      )
    );

    overlayRef.backdropClick().subscribe(() => {
      //overlayRef.detach();
      console.log('backdrop click');
    });

    const compRef = overlayRef.attach(contextOverlay);
    compRef.instance.id = id;
    compRef.instance.overlayRef = overlayRef;

    compRef.instance.childid = childid;
    if (data) {
      compRef.instance['data'] = data;
    }
    if (loadas) {
      compRef.instance['loadas'] = loadas;
    }
    if (outlet) {
      compRef.instance['outlet'] = outlet;
    }
    if (onclose) {
      compRef.instance['onclose'] = onclose;
    }
    if (oncancel) {
      compRef.instance['oncancel'] = oncancel;
    }    
    compRef.instance.contextRef = overlayRef;
    if (origin) {
      compRef.instance.origin = origin;
    }
    if (info) {
      compRef.instance.info = info;
    }

    compRef.instance.parentElement = window;

    if (position) {
      compRef.location.nativeElement.style.position = 'absolute';
      console.log(`${position.x}px`, `${position.y}px`)
      compRef.location.nativeElement.style.left = `${position.x}px`;
      compRef.location.nativeElement.style.top = `${position.y}px`;
    }
    //compRef.location.nativeElement.style.position = 'absolute';
    //compRef.location.nativeElement.style.left = "100px"
    //compRef.location.nativeElement.style.top = "100px"
    //compRef.location.nativeElement.style.width = "500px"
    //compRef.location.nativeElement.style.height = "500px"
    if (data) {
      compRef.instance.setData(data);
    }
    compRef.changeDetectorRef.detectChanges();
    return compRef.instance;
  };

  openmenuparam = (menuparams:MenuParams) => {
    this.openmenu(
      menuparams.id,
      menuparams.childid,
      menuparams.origin,
      menuparams.event,
      menuparams.info,
      menuparams.data,
      menuparams.loadas,
      menuparams.outlet,
      menuparams.position,
      menuparams.isparent
    )
  }

  openmenu = (
    id: number,
    childid?: number,
    origin?: number,
    event?: Event,
    info?: unknown,
    data?: unknown,
    loadas?: number,
    outlet?: number,
    position? : any,
    isparent? : any
  ) => {
    if (event) {
      this.currentevent = event;
    }

    this.currentevent?.stopPropagation();
    this.currentevent?.preventDefault();

    const overlayRef = this.overlay.create({});

    const widgetType = this.registry.getWidgetType('iscontext2overlay');
    if (widgetType) {
      const contextOverlay = new ComponentPortal(widgetType);
      contextOverlay['menu'] = true;

      overlayRef.backdropClick().subscribe(() => {
        overlayRef.detach();
      });

      const compRef = overlayRef.attach(contextOverlay);
      const component: any = compRef.instance;
      component.id = id;
      component.overlayRef = overlayRef;
      component.childid = childid?childid:id+1;
      if (data) {
        component['data'] = data;
      }
      if (isparent) {
        component['isparent'] = isparent;
      }
      if (loadas) {
        component['loadas'] = loadas;
      }
      if (info) {
        component['info'] = info;
      }
      if (outlet) {
        component['outlet'] = outlet;
      }
      component.contextRef = overlayRef;
      if (origin) {
        component.origin = origin;
      }
      component.addClickListner();

      if (info) {
        component.info = info;
      }

      component.parentElement = this.currentevent?.currentTarget;
      compRef.location.nativeElement.style.position = 'absolute';
      compRef.location.nativeElement.style.left =
        (position?.x ? position.x : this.currentevent['clientX']).toString() + 'px';
      compRef.location.nativeElement.style.top =
      (position?.y ? position.y : this.currentevent['clientY']).toString() + 'px';
      compRef.changeDetectorRef.detectChanges();
      return component;
    }
  };

  openwizard = () => {};

  openform = (outletid, componentid, comptype?, data?, loadas?, params?) => {
    this.store?.dispatch(
      new LoadComponent({
        id: componentid,
        name: '',
        outlet: outletid,
        comptype: comptype ? comptype : 0,
        data: data ? data : null,
        loadas: loadas ? loadas : null,
        route: params ? params : null,
      })
    );
    this.currentevent.stopPropagation();
    return false;
  };

  openformname = (outletid, name, comptype?, data?, loadas?, params?) => {
    return this.store?.dispatch(
      new LoadComponent({
        id: -1,
        name: name,
        outlet: outletid,
        comptype: comptype ? comptype : 0,
        data: data ? data : null,
        loadas: loadas ? loadas : null,
        route: params ? params : null,
      })
    );
  };

  openreport = (outletid, componentid) => {
    this.store?.dispatch(
      new LoadComponent({ id: componentid, name: '', outlet: outletid })
    );
  };

  setExtra(key, obj) {
    this[key] = obj;
  }

  component(id: number) {
    return this.comp?.widgets.get(id)?.instance;
  }
}

let componentserviceInstanceNr = 0;

@Injectable({ providedIn: 'root' })
export class ComponentsService {
  widgets: Map<number, ComponentRef<any>> = new Map<
    number,
    ComponentRef<any>
  >();
  //defaultStyles:Map<number, any>=new Map<number, any>();
  defaultStylesSub: Map<
    number,
    Map<string, string | number | Record<string, string | number>>
  > = new Map<number, Map<string, string | number>>();
  //hoverStyles:Map<number, any>=new Map<number, any>();
  hoverStylesSub: Map<number, Map<string, any>> = new Map<
    number,
    Map<string, any>
  >();
  additionalStylesSub: Map<number, Map<string, any>> = new Map<
    number,
    Map<string, any>
  >();
  classes: Map<number, string[]> = new Map<number, string[]>();
  formgroup: Map<number, FormGroup> = new Map<number, FormGroup>();
  events: Map<number, EventMap[]> = new Map<number, EventMap[]>();
  functions: Map<number, Map<string, Function>> = new Map<
    number,
    Map<string, Function>
  >();
  validators: Map<number, Map<string, Function>> = new Map<
    number,
    Map<string, Function>
  >();
  funcnames: Map<number, Map<string, Function>> = new Map<
    number,
    Map<string, Function>
  >();
  messagehandlers: Map<number, Map<string, Function>> = new Map<
    number,
    Map<string, Function>
  >();
  setters: Map<number, Map<string, Function>> = new Map<
    number,
    Map<string, Function>
  >();
  formcontrols: Map<
    string,
    {
      name: string;
      formcontrol: FormControl;
      master?: number[];
      in_use?: Boolean;
    }
  > = new Map<
    string,
    {
      name: string;
      formcontrol: FormControl;
      master?: number[];
      in_use?: Boolean;
    }
  >();
  gridCanvases: Map<number, any> = new Map<number, any>();
  renderer: Renderer2;
  style: any;
  styleSheet: any;
  styleruleOrder: number[] = [];
  theme = 'is';
  language = '';
  appname = '';

  Dexie = Dexie;
  exportDB = exportDB;
  importDB = importDB;
  flattenTree = flattenTree;
  cloneDeep = cloneDeep;

  private _componentserviceInstanceNr: number;

  get componentserviceInstanceNr() {
    return this._componentserviceInstanceNr;
  }  

  constructor(
    private registry: WidgetsRegistryService,
    private widgetsLoader: WidgetsLoaderService,
    //private componentFactoryResolver: ComponentFactoryResolver,
    //private injector: Injector,
    private rendererFactory: RendererFactory2,
    private themeService: IsthemingService,
    private cssService: CSSService,
    private store: Store,
    private overlay: Overlay,
    private route:ActivatedRoute
  ) {
    this._componentserviceInstanceNr = componentserviceInstanceNr++;
    this.renderer = rendererFactory.createRenderer(null, null);
    this.style = document.createElement('style');
    document.head.appendChild(this.style);
    this.styleSheet = this.style.sheet;

    const activeComposite = this.themeService.getActiveComposite();
    this.styleSheet.insertRule(
      ':root ' + this.themeService.getCompositeCSSString(activeComposite)
    );

    this.themeService.themeChange.subscribe((composite) =>
      this.updateTheme(composite)
    );

    this.theme = 'is';

    const str = this.cssService.JSONtoCSSRules(this.cssService.makeColReady(500, 100));
    [this.language] = window.navigator.language.split('-');
    console.log('language browser = ',this.language);
    if (!['nl','fr','en'].includes(this.language)){
      this.language = 'fr';
    }

    this.route.queryParams.subscribe(params => {
      const { lang } = params;
       this.language = lang?lang:this.language;
       if (!['nl','fr','en'].includes(this.language)){
        this.language = 'fr';
      }
    });

    // console.trace(
    //    `%cClass: componentService, Function: constructor(this.componentserviceInstanceNr): `,
    //    'color: black;',
    //    this.componentserviceInstanceNr
    // );
    
  }

  get appconfig(){
    return this.store.selectSnapshot(state => state['isapplicationstate'].appconfig);
  }

  get datasets(){
    return this.store.selectSnapshot(state => state['isapplicationstate'].dssets);
  }

  datasetsobs(dsname:string){
    return this.store.select(state => state['isapplicationstate'].dssets[dsname]);
  }

  obserervedatasetrecord(dsname:string,fn:Function){
    return this.store.select(state => {
      //console.log('obserervedatasetrecord : ');
      return state['isapplicationstate'].dssets[dsname][state.isapplicationstate.dssets[dsname].findIndex(fn)]
    });
  }

  obserervedatasetbykey(dsname:string,keyvalue:any){
    return this.store.select(state => {
      //console.log('obserervedatasetbykey : ');
      return state['isapplicationstate'].dssets[dsname][keyvalue]
    });
  }

  private getOverlayConfig(config: isDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      width: config.width,
      height: config.height,
    });

    return overlayConfig;
  }

  opendialog1 = (params: DialogParams) => {
    // if (params.event) {
    //   this.currentevent = params.event;
    // }

    // this.currentevent?.stopPropagation();
    // this.currentevent?.preventDefault();

    const overlayRef = this.isMobile()
      ? this.overlay.create(this.getOverlayConfig({ ...ISMOBILE_CONFIG }))
      : this.overlay.create(this.getOverlayConfig({ ...ISDEFAULT_CONFIG }));

    const contextOverlay = new ComponentPortal(
      <ComponentType<Iscontextoverlay2Component>>this.registry.getWidgetType('iscontext2overlay')
    );

    overlayRef.backdropClick().subscribe(() => {
      //overlayRef.detach();
      console.log('backdrop click');
    });

    const compRef: any = overlayRef.attach(contextOverlay);
    compRef.instance.id = params.id;
    compRef.instance.overlayRef = overlayRef;

    compRef.instance.childid = params.childid ? params.childid : (params.id ? params.id : 0) + 1;

    if (params.data) {
      compRef.instance['data'] = params.data;
    }
    if (params.isparent) {
      compRef.instance['isparent'] = params.isparent;
    }
    if (params.loadas) {
      compRef.instance['loadas'] = params.loadas;
    }
    if (params.outlet) {
      compRef.instance['outlet'] = params.outlet;
    }
    if (params.onclose) {
      compRef.instance['onclose'] = params.onclose;
    }
    if (params.oncancel) {
      compRef.instance['oncancel'] = params.oncancel;
    }
    compRef.instance.contextRef = overlayRef;
    if (origin) {
      compRef.instance.origin = params.origin;
    }
    if (params.info) {
      compRef.instance.info = params.info;
    }

    compRef.instance.parentElement = window;

    if (params.data) {
      compRef.instance.setData(params.data);
    }
    compRef.changeDetectorRef.detectChanges();
    return compRef.instance;
  };
  /*
  <div lastLevel [id]="id" style="height:100%;width:100%;display:'flex'">
  <div #widgetLayer widgetLayer [id]="id" style="height:100%;width:100%;">
      <ng-template #widgets></ng-template>
  </div>
  </div>*/

  updateTheme(composite) {
    const str = this.themeService.getCompositeCSSString(composite);
    for (let i = 0; i < this.styleSheet.cssRules.length; i++) {
      if (this.styleSheet.cssRules[i].selectorText.startsWith(':root')) {
        this.styleSheet.removeRule(i);
      }
    }
    this.styleSheet.insertRule(':root ' + str);
  }

  createemptyform(element) {
    const cmpRef = this.renderer.createElement('div');
    this.renderer.appendChild(cmpRef, element);
    return cmpRef;
  }

  addformgroup(id: number) {
    const fg = new FormGroup({});
    this.formgroup.set(id, fg);
    return fg;
  }

  removeformgroup(id: number) {
    this.formgroup.delete(id);
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  updateid(id, compRef) {
    if (id != null) {
      this.widgets.set(id, compRef);
    }
  }

  fc(formcontrolname: string) {
    return this.formcontrols?.get(formcontrolname)?.formcontrol;
  }

  fcv(formcontrolname: string) {
    return this.formcontrols?.get(formcontrolname)?.formcontrol?.value;
  }

  ge(id: number) {
    return this.events?.get(id);
  }

  gcr(id: number) {
    return this.widgets?.get(id);
  }

  gci(id: number) {
    return this.widgets?.get(id)?.instance;
  }

  gc(id: number) {
    return this.widgets?.get(id)?.instance;
  }

  gcis(id: string) {
    return this.widgets?.get(parseInt(id))?.instance;
  }

  gcl(id: number) {
    return this.widgets?.get(id)?.location;
  }

  el(id: number) {
    return this.widgets?.get(id)?.location.nativeElement;
  }

  dispnone(comp) {
    this.renderer.setStyle(comp.el.nativeElement, 'display', 'none');
  }

  dispon(comp:any,displaymode:string) {
    this.renderer.setStyle(comp.el.nativeElement, 'display', displaymode);
  }

  isMobile(): boolean {
    return window.navigator.maxTouchPoints > 0 && 
       (window.navigator.userAgent.includes('Android') || 
       window.navigator.userAgent.includes('iPhone') || 
       window.navigator.userAgent.includes('iPad'));
  }

  async createWidget(
    widget: IIsComponent,
    runcd?,
    exists?,
    data?,
    classasid?: number
  ) {
    if (widget.type == undefined) return;
    if (!exists) {
      exists = this.widgets.get(widget.id);
    }
    if (!exists || exists === 1 && (widget.type !== 'setter')) {
      const compRef = await this.widgetsLoader.getNewComponentInstance(
        widget.type
      );
      this.stabComponent(compRef, widget, runcd, data);
      //this.widgets.set(widget.id,compRef);
      //this.widgets.set(widget.parentid,compRef);
    }
    return this.widgets.get(widget.id);
  }

  private stabComponent(
    compRef: ComponentRef<unknown>,
    widget,
    runcd?,
    data?,
    classasid?: number
  ) {
    this.updateid(widget.id, compRef);
    compRef.location.nativeElement.setAttribute(
      'class',
      this.theme + '-' + (classasid ?? widget.id.toString())
    );
    compRef.location.nativeElement.setAttribute('_isid', widget.id);
    compRef.location.nativeElement.setAttribute('isname', widget.name);

    /*  if (widget.sizes != null) {
      (compRef as ComponentRef<any>).instance.sizes = JSON.parse(JSON.stringify(widget.sizes));
    }  */

    compRef.instance['version'] = 1;
    if (data) {
      compRef.instance['data'] = data;
    }

    for (const [key, value] of Object.entries(widget)) {
      if (value != null) {
        if (
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean'
        ) {
          compRef.instance[key] = value;
        } else if (value.constructor === Array) {
          compRef.instance[key] = value;
        }
      }
    }

    if (widget.config) {
      // eslint-disable-next-line prefer-const
      for (let [key, value] of Object.entries(widget.config)) {
        if (value != null) {
          if (
            typeof value === 'string' ||
            typeof value === 'number' ||
            typeof value === 'boolean'
          ) {
            compRef.instance[key] = value; // do not move this line to bottom of this section
            if (data) {
              for (const [key, dvalue] of Object.entries(data)) {
                const regex = '{{' + key + '}}';
                value = value
                  .toString()
                  .replace(regex, (dvalue ? dvalue : '') as string);
              }
            }
          } else if (value.constructor === Array) {
            compRef.instance[key] = value;
          } else if (typeof value === 'object') {
            compRef.instance[key] = Object.assign({}, value);
          }
        }
      }
    }

    if (compRef.instance['translate']){
      let { lang } = this.route.snapshot.queryParams;
      lang = !['fr','nl','en'].includes(lang)?this.language:lang;
      try {
        compRef.instance['translate'](lang ? lang : this.language);
      } catch(err) {
          console.log(
            'Translate ',
            widget.id,
            ' ',
            err.message
          );

      }
    }

    if (!classasid) {
      this.classes.set(widget.id, []);
      this.defaultStylesSub.set(widget.id, new Map<string, any>());
      this.hoverStylesSub.set(widget.id, new Map<string, any>());
      //this.defaultStyles.set(widget.id, {})
      //this.hoverStyles.set(widget.id, {})

      this.setAttribs(widget, compRef, data);
      this.removeClassDefinition(widget.id);
      this.createWidgetStyleClass(widget.id, this.styleSheet);
      if (runcd) {
        if (widget.id != null) {
          this.widgets.set(widget.id, compRef);
        }
      }
      this.applyWidgetStyleClasses(widget.id, compRef);
    }

    if (!runcd) {
      compRef.changeDetectorRef.detectChanges();
    }
    if (widget.id != null) {
      this.widgets.set(widget.id, compRef);
    }
  }

  /* run oninit event just before angular ngOnInit */
  oninit(id, compRef) {}

  opendialog = (
    id: number,
    childid: number,
    origin?: number,
    event?: Event,
    info?: unknown,
    data?: unknown,
    loadas?: number,
    outlet?: number,
    onclose?: unknown,
    oncancel?: unknown
  ) => {
    /* borrow an eventhandler to call opendialog */
    const [firstEvent] = this.events.values();
    if (firstEvent.length > 0) {
      return firstEvent[0].opendialog(id, childid, origin, event, info, data, loadas, outlet, onclose, oncancel);
    } else {
      //const eventmap = new EventMap();
      //eventmap.opendialog(id, childid, origin, event, info, data, loadas, outlet, onclose);
    }
  };

  openform = (outletid, componentid, comptype?, data?, loadas?, params?, actionafterload?) => {
    return this.store?.dispatch(
      new LoadComponent({
        id: componentid,
        name: '',
        outlet: outletid,
        comptype: comptype ? comptype : 0,
        data: data ? data : null,
        loadas: loadas ? loadas : null,
        route: params ? params : null,
        actionafterload,
      })
    );
    return false
  };

  setAttribs(widget, compRef, data?) {
    if (widget.attribs) {
      for (let [key, value] of Object.entries(widget.attribs)) {
        if (value != null) {
          if (key[0] == '[') {
            let attribname: string;
            attribname = key.split('[')[1].split(']')[0];

            if (compRef['attfunc'] == null) {
              compRef['attfunc'] = {};
            }
            if (compRef['attfunc'][attribname] == null) {
              const args = ['data', 'return ' + value];
              compRef['attfunc'][attribname] = new Function(...args);
            }
            compRef.location.nativeElement.setAttribute(
              attribname,
              compRef['attfunc'][attribname](data ? data : null)
            );
          } else if (
            typeof value === 'string' ||
            typeof value === 'number' ||
            typeof value === 'boolean'
          ) {
            if (data) {
              for (const [dkey, dvalue] of Object.entries(data)) {
                const regex = '{{' + dkey + '}}';
                value = value.toString().replace(regex, dvalue as string);
              }
            }
            try {
              compRef.location.nativeElement.setAttribute(key, value);
            } catch (err) {
              console.log(
                'setAttribure widget',
                widget.id,
                ' ',
                key,
                ' ',
                value,
                ' ',
                err.message
              );
            }
          } else if (value.constructor === Array) {
            // compRef.instance[key] = value;
            if (key === 'class') {
              const temp: string[] = [];
              for (const [key, val] of Object.entries(value)) {
                compRef.location.nativeElement.classList.add(val);
                temp.push(val as string);
              }
              this.classes.set(widget.id, temp);
            }
          } else if (typeof value === 'object') {
            {
              //compRef.instance[key] = Object.assign({}, value);
              for (const [k, val] of Object.entries(value)) {
                if (k === 'static' && key == "style") {
                  const map = new Map<string, any>();                
                  for (const [key, v] of Object.entries(val)) {
                    map.set(key, v);
                  }
                  this.defaultStylesSub.set(widget.id, map);
                  // end test
                } else
                if (k === 'hover' && key == "style") {
                  const map = new Map<string, any>();                
                  for (const [key, v] of Object.entries(val)) {
                    map.set(key, v);
                  }
                  this.hoverStylesSub.set(widget.id, map);
                } 
              }
            }
          }
        }
      }

      if (widget.attribs.additionalStyle) {
        this.additionalStylesSub.set(widget.id, widget.attribs.additionalStyle);
      }

      if (widget.attribs.vars) {
        Object.entries(widget.attribs.vars).forEach(([key, val]) => {
          compRef.location.nativeElement.style.setProperty(key, val);
        })
      }
    }
  }

  classDefs( tag:string , id:number, classdefs:any, cmp:any) {
    if (classdefs) {
      const tmpDef = classdefs;
      if (cmp?.classdefs) {
        delete cmp.classdefs;
      }
      this.createClassDefinitions(tag+'-' + id, tmpDef);
    }
  }

  /* hovers with css */

  createWidgetStyleClass(id, styleSheet) {
    const css: string[] = [];

    function transformtocss(theme, name, defs, pseudo?) {
      if (defs) {
        defs.forEach((defvalue, defkey) => {
          // console.log('def : ',defkey,' ',defvalue);
          let cssstr = '';
          for (const [key, value] of Object.entries(defvalue)) {
            cssstr = `${cssstr + key}:${value};`;
          }
          const subname = defkey ? '-' + defkey : '';
          css.push(
            `[is] .${theme}-${id.toString()}${subname}${pseudo}${
              subname ? '[istag]' : ''
            } {${cssstr}} `
          );
          //  if((pseudo=='')&&(css.length>0)){
          //    defs[defkey]['class'] = '.is-'+name+subname;
          //  }
        });
      }
    }

    function transformtocss2(theme, id, name, defs) {
      if (defs) {
          let cssstr = '';

          for (const [key, value] of Object.entries(defs)) {
            cssstr = `${cssstr + key}:${value};`;
          }
          css.push(
           `[is] .${theme}-${id}${name} {${cssstr}} `
           //`${name} {${cssstr}} `
          );
      }
    }

    transformtocss(
      this.theme,
      id.toString(),
      this.defaultStylesSub.get(id),
      ''
    );
    transformtocss(
      this.theme,
      id.toString(),
      this.hoverStylesSub.get(id),
      ':hover'
    );
    transformtocss(
      this.theme,
      id.toString(),
      this.hoverStylesSub.get(id),
      'isselected'
    );
    const addStyles = this.additionalStylesSub.get(id);

    if (addStyles) {
      Object.entries(addStyles).forEach(([key, val]) => {
        transformtocss2(
          this.theme,
          id,
          key,
          val
        );
      });
    }

    if (css.length > 0) {
      this.createClassDefinition(id, css, styleSheet);
    }
  }

  applyWidgetStyleClasses(id, compRef?) {
    const cmpRef = compRef ? compRef : this.widgets.get(id);
    this.defaultStylesSub.get(id).forEach((val, k) => {
      if (k) {
        const elements = cmpRef.location.nativeElement.querySelectorAll(
          '*[istag=' + k + ']'
        );
        elements.forEach((el) => {
          this.renderer.addClass(el, 'is-' + id.toString() + '-' + k);
        });
      }

      cmpRef.changeDetectorRef.detectChanges();

      /* else {
        this.addClass(widget.id,'is-'+widget.id.toString());
      } */
    });
  }

  /* hovers with styling and javascript */
  addAtribsEventHandlers(widget, compRef) {
    this.hoverStylesSub.get(widget.id).forEach((val, k) => {
      if (k) {
        const elements = compRef.location.nativeElement.querySelectorAll(
          '*[istag=' + k + ']'
        );
        elements.forEach((el) => {
          el.addEventListener('mouseenter', () => {
            Object.entries(val).forEach((a) => {
              this.setStyle(widget.id, a[0], a[1], k);
            });
          });
        });
      } else {
        this.widgets
          .get(widget.id)
          .location.nativeElement.addEventListener('mouseenter', () => {
            Object.entries(this.hoverStylesSub.get(widget.id).get(k)).forEach(
              (a) => {
                this.setStyle(widget.id, a[0], a[1], k);
              }
            );
          });
      }
    });

    this.defaultStylesSub.get(widget.id).forEach((val, k) => {
      if (k) {
        const elements = compRef.location.nativeElement.querySelectorAll(
          '*[istag=' + k + ']'
        );
        elements.forEach((el) => {
          Object.entries(val).forEach((a) => {
            this.setStyle(widget.id, a[0], a[1], k);
          });
          el.addEventListener('mouseleave', () => {
            Object.entries(val).forEach((a) => {
              this.setStyle(widget.id, a[0], a[1], k);
            });
          });
        });
      } else {
        this.widgets
          .get(widget.id)
          .location.nativeElement.addEventListener('mouseleave', () => {
            Object.entries(this.defaultStylesSub.get(widget.id).get(k)).forEach(
              (a) => {
                this.setStyle(widget.id, a[0], a[1], k);
              }
            );
          });
      }
    });
  }

  getWidgets() {
    return this.widgets;
  }

  setProperty(id, key, value, runcd: boolean = true) {
    const widget = this.widgets.get(id);
    if (widget) {
      widget.instance[key] = value;
      if (runcd) {
        widget.changeDetectorRef.detectChanges();
      }
    }
  }

  addClass(id, value, runcd: boolean = true) {
    const widget = this.widgets.get(id);
    if (widget) {
      this.renderer.addClass(widget.location.nativeElement, value);
      if (runcd) {
        widget.changeDetectorRef.detectChanges();
      }
    }
  }

  addClassTest(id, value) {
    const widget = this.widgets.get(id);
    if (widget) {
      this.renderer.addClass(widget.location.nativeElement, value);
    }
  }

  removeClassTest(id, value) {
    const widget = this.widgets.get(id);
    if (widget) {
      this.renderer.removeClass(widget.location.nativeElement, value);
    }
  }

  setAttribute(
    id: number,
    key: string,
    value: string = '',
    runcd: boolean = true
  ) {
    const widget = this.widgets.get(id);
    // if(widget){
    //   if (widget.instance.el){
    //     this.renderer.setAttribute(widget.instance.el.nativeElement,key,value);
    //     if(runcd){
    //       widget.changeDetectorRef.detectChanges()
    //     }
    //   }
    // }
    if (widget) {
      if (value) {
        this.renderer.setAttribute(widget.location.nativeElement, key, value);
      }
      if (runcd) {
        widget.changeDetectorRef.detectChanges();
      }
    }
  }

  removeAttribute(id, key, runcd: boolean = true) {
    const widget = this.widgets.get(id);
    if (widget) {
      this.renderer.removeAttribute(widget.instance.el.nativeElement, key);
      if (runcd) {
        widget.changeDetectorRef.detectChanges();
      }
    }
  }

  removeClass(id, value, runcd: boolean = true) {
    const widget = this.widgets.get(id);
    if (widget) {
      this.renderer.removeClass(widget.location.nativeElement, value);
      widget.changeDetectorRef.detectChanges();
    }
  }

  setStyle(id, property: string, value, element?) {
    if (value == 'null') {
      value = null;
    }
    const widget = this.widgets.get(id);
    if (widget) {
      if (element != '' && element) {
        // let el;
        widget.location.nativeElement.querySelectorAll('*').forEach((el) => {
          if (el.hasAttribute('istag')) {
            if (el.attributes.istag.value == element) {
              this.renderer.setStyle(el, property, value);
            }
          }
        });
        //this.renderer.setStyle(widget.location.nativeElement.querySelector(element), property, value);
      } else {
        this.renderer.setStyle(widget.location.nativeElement, property, value);
      }
    }
  }

  refreshwidget(id) {
    const widget = this.widgets.get(id);
    widget.changeDetectorRef.detectChanges();
  }

  setDefaultStyleSub(id, property, value) {
    const defaultSub = this.defaultStylesSub.get(id);
    if (defaultSub) {
      defaultSub.set('', {
        ...(<Record<string, string | number>>defaultSub.get('')),
        [property]: value,
      });
      // const currentSub = defaultSub.get('');
      // currentSub[property] = value;
    }

    const styleeditor = this.widgets.get(254);
    if (styleeditor) {
      styleeditor.instance.formStyle.get(property).setValue(value);
    }
  }

  removeStyle(id, property: string) {
    const widget = this.widgets.get(id);
    if (widget) {
      this.renderer.removeStyle(widget.location.nativeElement, property);
    }
  }

  findisparent(comp:any,name:string,type?:string) {     
      function withtype(){
        if (name!=null){
          return p.type==type&&p.name==name;
        } else
        {
          return p.type==type;
        }
      }
      
      function withouttype(){
        return p.name==name;
      }

     let p = comp.isparent;
     const f = type?withtype:withouttype;

     while (p!=null && !f()){
      p = p.isparent?p.isparent:null;
     }

     return p;
  }

  availableEventId(compid) {
    console.log(this.events.get(compid));
    return this.events.get(compid).length ? this.events.get(compid).length : 1;
  }

  setEvent(compid, eventmap, compsToAdd?) {
    if (!compsToAdd) {
      compsToAdd = [];
    }

    const comp = this.widgets.get(compid);
    eventmap.setExtra('widgets', this.widgets);
    eventmap.setExtra('self', comp?.instance);
    //eventmap.setExtra('element',comp.location.nativeElement);
    //eventmap.setExtra('id',compid);
    for (const compid of compsToAdd) {
      if (compid > 0) {
        eventmap.setExtra('comp' + compid.toString(), this.widgets.get(compid));
      } else {
        eventmap.setExtra(
          'newcomp' + (-1 * compid).toString(),
          this.widgets.get(compid)
        );
      }
    }
    //eventmap.self = this.widgets.get(compid)

    let currentEvents = this.events.get(compid);

    let natEl = this.widgets.get(compid).location.nativeElement;

    if (eventmap.specific) {
      //.menu-item
      natEl = natEl.querySelector(eventmap.specific);
    }

    if (eventmap.selectall) {
      natEl = natEl.querySelectorAll(eventmap.selectall);
    }

    if (!currentEvents) {
      currentEvents = [];
    }
    const idx = currentEvents.findIndex((item) => {
      return item.id == eventmap.id;
    });

    if (idx !== -1) {
      if (eventmap.selectall) {
        for (const el of natEl) {
          el.removeEventListener(
            currentEvents[idx].eventtype,
            currentEvents[idx].function
          );
        }
      } else {
        natEl.removeEventListener(
          currentEvents[idx].eventtype,
          currentEvents[idx].function
        );
      }
      currentEvents[idx] = eventmap;
    } else {
      currentEvents.push(eventmap);
    }

    if (eventmap.selectall) {
      for (const el of natEl) {
        el.addEventListener(eventmap.eventtype, eventmap.function);
      }
    } else {
      natEl.addEventListener(eventmap.eventtype, eventmap.function);
    }

    this.events.set(compid, currentEvents);
    return idx;
  }

  removeEvent(compid, eventid) {
    const events = this.events.get(compid);

    const currentEvent = events.find((e) => e.id === eventid);
    const natEl = this.widgets.get(compid).location.nativeElement;

    natEl.removeEventListener(currentEvent.eventtype, currentEvent.function);

    this.events.set(
      compid,
      events.filter((e) => e.id !== eventid)
    );
  }

  resetEvents(compid: number) {
    const events = this.events.get(compid);

    events?.forEach((e) => this.setEvent(compid, e));
  }

  removeFunction(compid, functionname) {
    const functions = this.functions.get(compid);
    const cmp = this.widgets.get(compid);

    cmp.instance[functionname] = null;

    functions.delete(functionname);
  }

  seteventsoncomponent(component: IIsComponent) {
    if (component.events) {
      const event = produce(component.events, (draft) => {
        for (const ev of draft) {
          ev.parentid = component.id;
          const func = new Function(ev.config.code);
          const eventmap = new EventMap(
            ev.name,
            ev.config.type,
            func,
            ev.id,
            this.overlay,
            this.registry,
            ev.parentid,
            ev.config.specific,
            ev.config.selectall,
            this,
            this.store
          );
          this.setEvent(component.id, eventmap, ev.config.ref);
        }
      });
    }
    if (component.children) {
      component.children.map((child) => {
        if (component.events) {
          this.seteventsoncomponent(child);
        }
      });
    }
  }

  setClassOnElements(id, value, element) {
    const widget = this.widgets.get(id);
    if (widget) {
      // this.renderer.addClass(widget.location.nativeElement.querySelector(element), value);
      widget.location.nativeElement.querySelectorAll(element).forEach((a) => {
        this.renderer.addClass(a, value);
      });
    }
  }

  removeClassElements(id, value, element) {
    const widget = this.widgets.get(id);
    if (widget) {
      widget.location.nativeElement.querySelectorAll(element).forEach((a) => {
        this.renderer.removeClass(a, value);
      });
    }
  }

  getDescendants(id): string[] {
    const list = [];
    this.widgets
      .get(id)
      .location.nativeElement.querySelectorAll('*')
      .forEach((element) => {
        // if (list.indexOf(element.nodeName) == -1) {
        //   list.push(element.nodeName);
        // }
        if (element.hasAttribute('istag')) {
          const tags = element.attributes.istag.value.split(' ');
          tags.forEach((tag) => {
          if (list.indexOf(tag) == -1) {
            list.push(tag);
          }
        })
        }
      });
    return list;
  }

  addTheme(tree) {
    tree.sizes.forEach((a) => {
      const def = this.defaultStylesSub.get(a.id);

      def.forEach((val, k) => {
        let staticValues;
        staticValues = this.valuesToString(val);
        let hoverValues;
        if (hoverValues) {
          hoverValues = this.valuesToString(
            this.hoverStylesSub.get(a.id).get(k)
          );
        }

        const parent = tree.name;
        const component = this.widgets.get(a.id).instance.type;
        const sub = k;

        let staticClassString;
        let hoverClassString;
        if (sub == '') {
          staticClassString = `.${parent} ${component} ${staticValues}`;
          if (hoverValues) {
            hoverClassString = `.${parent} ${component}:hover${hoverValues}`;
          }
        } else {
          staticClassString = `.${parent} ${component} .${sub} ${staticValues}`;
          if (hoverValues) {
            hoverClassString = `.${parent} ${component} .${sub}:hover${hoverValues}`;
          }
        }

        this.addStyleElement(staticClassString, parent);
        if (hoverValues && hoverValues != staticValues) {
          this.addStyleElement(hoverClassString, parent);
        }
      });
    });
  }

  addStyleElement(textContent, parent) {
    let node = document.getElementById(parent);
    if (!node) {
      node = document.createElement('style');
      node.id = parent;
    }
    node.textContent += textContent;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  valuesToString(values): string {
    const nonNullValues = {};
    Object.entries(values).forEach((a) => {
      if (a[1]) {
        nonNullValues[a[0]] = a[1];
      }
    });

    let string = JSON.stringify(nonNullValues);
    const regex = /\"/gi;
    const regex2 = /,/gi;
    string = string.replace(regex, '');
    string = string.replace(regex2, ';');
    return string;
  }

  getFormStyle(id, values) {
    let element = values.element;
    if (!element) {
      element = '';
    }
    if (values.mode == 'static') {
      return this.defaultStylesSub.get(id).get(element)
        ? this.defaultStylesSub.get(id).get(element)
        : {};
    }
    if (values.mode == 'hover') {
      return this.hoverStylesSub.get(id).get(element)
        ? this.hoverStylesSub.get(id).get(element)
        : {};
    }
  }

  setComponentWidgetClass(id, values, stylesEmpty, stylesNull) {
    let element = values.element;
    if (!element) {
      element = '';
    }
    const styleMap =
      values.mode == 'static' ? this.defaultStylesSub : this.hoverStylesSub;
    styleMap.get(id).set(element, stylesEmpty);
    this.removeClassDefinition(id);
    this.createWidgetStyleClass(id, this.styleSheet);
    this.applyWidgetStyleClasses(id);
  }

  // setFormStyle(id, values, stylesEmpty, stylesNull) {
  //   let element = values.element;
  //   if (!element) {
  //     element = '';
  //   }
  //   if (values.mode == 'static') {
  //     let addEvent = false;
  //     if (!this.defaultStylesSub.get(id).get(element)) {
  //       addEvent = true;
  //     }
  //     this.defaultStylesSub.get(id).set(element, stylesEmpty);
  //     Object.entries(stylesNull).forEach((entry) => {
  //       this.setStyle(id, entry[0], entry[1], element);
  //     });
  //     if (addEvent) {
  //       if (element) {
  //         const els = this.widgets.get(id).location.nativeElement.querySelectorAll('*[istag=' + element + ']');
  //         els.forEach((el) => {
  //           el.addEventListener('mouseleave', () => {
  //             Object.entries(this.defaultStylesSub.get(id).get(element)).forEach((a) => {
  //               this.setStyle(id, a[0], a[1], element);
  //             });
  //           });
  //         });
  //       } else {
  //         this.widgets.get(id).location.nativeElement.addEventListener('mouseleave', () => {
  //           Object.entries(this.defaultStylesSub.get(id).get(element)).forEach((a) => {
  //             this.setStyle(id, a[0], a[1], element);
  //           });
  //         });
  //       }
  //     }
  //   }
  //   if (values.mode == 'hover') {
  //     let addEvent = false;
  //     if (!this.hoverStylesSub.get(id).get(element)) {
  //       addEvent = true;
  //     }
  //     this.hoverStylesSub.get(id).set(element, stylesEmpty);
  //     if (addEvent) {
  //       if (element) {
  //         const els = this.widgets.get(id).location.nativeElement.querySelectorAll('*[istag=' + element + ']');
  //         els.forEach((el) => {
  //           el.addEventListener('mouseenter', () => {
  //             Object.entries(this.hoverStylesSub.get(id).get(element)).forEach((a) => {
  //               this.setStyle(id, a[0], a[1], element);
  //             });
  //           });
  //         });
  //       } else {
  //         this.widgets.get(id).location.nativeElement.addEventListener('mouseenter', () => {
  //           Object.entries(this.hoverStylesSub.get(id).get(element)).forEach((a) => {
  //             this.setStyle(id, a[0], a[1], element);
  //           });
  //         });
  //       }
  //     }
  //   }
  // }

  // updatestyleids(ids) {
  //   ids.map((id) => {
  //     function replace(style) {
  //       const s = style.get(id.remoteid);
  //       if (s) {
  //         style.delete(id.remoteid);
  //         style.set(id.localid, s);
  //       }
  //     }
  //     //replace(this.defaultStyles);
  //     replace(this.defaultStylesSub);
  //     //replace(this.hoverStyles);
  //     replace(this.hoverStylesSub);
  //   });
  // }

  onAttach(comp) {
    if (comp.instance.sizes) {
      for (const widget of comp.instance.sizes) {
        this.onAttach(this.widgets.get(widget.id));
      }
    }
    if (comp.instance.onAttach) {
      comp.instance.onAttach();
    }
  }

  onDetach(comp) {
    if (comp.instance.sizes) {
      for (const widget of comp.instance.sizes) {
        this.onDetach(this.widgets.get(widget.id));
      }
    }
    if (comp.instance.onDetach) {
      comp.instance.onDetach();
    }
  }

  createClassDefinitions(name, defs) {
    //console.log(defs);
    const css: string[] = [];
    for (const [defkey, defvalue] of Object.entries(defs)) {
      //console.log('def : ', defkey, ' ', defvalue);
      let cssstr = '';
      for (const [key, value] of Object.entries(defvalue)) {
        cssstr = cssstr + key + ':' + value + ';';
      }
      css.push('[is] .' + name + '-' + defkey + ' {' + cssstr + '}' + ' ');
    }
    this.createClassDefinition(name, css, this.styleSheet);
  }

  createClassDefinition(id, css, stylesheet) {
    if (css) {
      css.map((rule) => {
        const ruleIdx = stylesheet.insertRule(rule, 0);
        // let ruleIdx = this.styleSheet.insertRule(rule, this.styleSheet.cssRules.length)
        this.styleruleOrder.splice(ruleIdx, 0, id);
      });
    }
  }

  roundToTwo(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  getWeek(date): number {
    const day = new Date(date);
    const firstDayOfYear = new Date(day.getFullYear(), 0, 1);
    const pastDaysOfYear =
      (day.valueOf() - firstDayOfYear.valueOf()) / 86400000;
    return /*day.getFullYear()*100+*/ Math.ceil(
      (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
    );
  }

  hourstoHMS(h: number) {
    const sec_num = h * 3600; // don't forget the second param
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - hours * 3600) / 60);
    const seconds = sec_num - hours * 3600 - minutes * 60;

    const hs = hours < 10 ? '0' + hours.toString() : hours.toString();
    const ms = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
    const ss =
      seconds < 10
        ? '0' + seconds.toString().slice(0, 1)
        : seconds.toString().slice(0, 2);

    return hs + ':' + ms + ':' + ss;
  }

  getCurrentStrDate() {
    const dt = new Date(); //
    const ds = dt.getDate();
    const ms = dt.getMonth() + 1;
    const dss = ds < 10 ? '0' + ds.toString() : ds.toString();
    const mss = ms < 10 ? '0' + ms.toString() : ms.toString();
    return dss + '-' + mss + '-' + dt.getFullYear().toString();
  }

  getDbStrDate(offset: number) {
    const dt = new Date(); //
    let ds = dt.getDate();
    ds = ds + offset;
    const ms = dt.getMonth() + 1;
    const dss = ds < 10 ? '0' + ds.toString() : ds.toString();
    const mss = ms < 10 ? '0' + ms.toString() : ms.toString();
    return mss + '/' + dss + '/' + dt.getFullYear().toString();
  }

  setdatetoformat(date: any, format: string, returnformat?: string) {
    function dtos(date: any, format?: string) {
      let localDate;
      const ds = date.getDate();
      if (ds == 'NaN') return null;
      const ms = date.getMonth() + 1;
      const dss = ds < 10 ? '0' + ds.toString() : ds.toString();
      const mss = ms < 10 ? '0' + ms.toString() : ms.toString();
      const yss = date.getFullYear().toString();
      switch (format) {
        case 'dd-mm-yyyy':
          {
            localDate = dss + '-' + mss + '-' + yss;
          }
          break;
        case 'dd/mm/yyyy':
          {
            localDate = dss + '/' + mss + '/' + yss;
          }
          break;
        case 'mm/dd/yyyy':
          {
            localDate = mss + '/' + dss + '/' + yss;
          }
          break;
        case 'mm-dd-jjjj':
          {
            localDate = mss + '-' + dss + '-' + yss;
          }
          break;
        default:
          {
            localDate = mss + '/' + dss + '/' + yss;
          }
          break;
      }
      return localDate;
    }

    if (typeof date == 'string') {
      let date_components = [];
      let day, month, year: number;

      switch (format) {
        case 'dd-mm-yyyy':
          {
            date_components = date.split('-').map((item) => parseInt(item));
            day = date_components[0];
            month = date_components[1];
            year = date_components[2];
          }
          break;
        case 'dd/mm/yyyy':
          {
            date_components = date.split('/').map((item) => parseInt(item));
            day = date_components[0];
            month = date_components[1];
            year = date_components[2];
          }
          break;
        case 'mm/dd/yyyy':
          {
            date_components = date.split('/').map((item) => parseInt(item));
            day = date_components[1];
            month = date_components[0];
            year = date_components[2];
          }
          break;
        case 'mm-dd-jjjj':
          {
            date_components = date.split('-').map((item) => parseInt(item));
            day = date_components[1];
            month = date_components[0];
            year = date_components[2];
          }
          break;
        default:
          {
            date_components = date.split('/').map((item) => parseInt(item));
            day = date_components[1];
            month = date_components[0];
            year = date_components[2];
          }
          break;
      }
      if (format != returnformat) {
        return dtos(new Date(year, month - 1, day), returnformat);
      } else {
        return new Date(year, month - 1, day);
      }
    } else {
      return date==null?null:dtos(date, format);
    }
  }

  getDbStrDateTime(offset: number) {
    const dt = new Date(); //
    let ds = dt.getDate();
    ds = ds + offset;
    const ms = dt.getMonth() + 1;
    const dss = ds < 10 ? '0' + ds.toString() : ds.toString();
    const mss = ms < 10 ? '0' + ms.toString() : ms.toString();
    const h = dt.getHours();
    const m = dt.getMinutes();
    const s = dt.getSeconds();
    const hour = h < 10 ? '0' + h.toString() : h.toString();
    const min = m < 10 ? '0' + m.toString() : m.toString();
    const sec = s < 10 ? '0' + s.toString() : s.toString();
    return (
      mss +
      '/' +
      dss +
      '/' +
      dt.getFullYear().toString() +
      ' ' +
      hour +
      ':' +
      min +
      ':' +
      sec
    );
  }

  timeToHMS(time:Date,sec=true){
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = sec?time.getSeconds():null;
    const firstpart = `${hours < 10 ? '0' + hours.toString() : hours}:${minutes < 10 ? '0' + minutes.toString() : minutes}`

    return seconds!=null?firstpart+`:${seconds < 10 ? '0' + seconds?.toString() : seconds}`:firstpart;
  }

  getDbFirstMonthDate() {
    const dt = new Date(); //
    const ms = dt.getMonth() + 1;
    const mss = ms < 10 ? '0' + ms.toString() : ms.toString();
    return mss + '/01/' + dt.getFullYear().toString();
  }

  toDay(someDate) {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }

  isDateBeforeToday(date) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
  }

  isDateAfterToday(date) {
    return new Date(date.toDateString()) > new Date(new Date().toDateString());
  }

  removeClassDefinition(id) {
    while (this.styleruleOrder.indexOf(id) != -1) {
      const idx = this.styleruleOrder.indexOf(id);
      this.styleSheet.deleteRule(this.styleruleOrder.indexOf(id));
      this.styleruleOrder.splice(idx, 1);
    }
    // for (let i = 0; i < this.styleSheet.cssRules.length; i++) {
    //   let a = this.styleSheet.cssRules[i]
    //   let idx = Number(a.selectorText.substr(a.selectorText.indexOf("-") + 1, a.selectorText.length))
    //   if (idx == id) {
    //     this.styleSheet.deleteRule(i)
    //   }
    //   // debugger;
    // }
  }

  removeClassBySelector(selector) {
    for (let i = 0; i < this.styleSheet.cssRules.length; i++) {
      if (this.styleSheet.cssRules[i].selectorText == selector) {
        this.styleSheet.deleteRule(i);
      }
    }
  }

  setGridStyle(id, mode, color) {
    if (mode == 'lines') {
      this.gridCanvases.set(id, { lines: true, color: color });
    } else {
      this.gridCanvases.set(id, { lines: false });
    }
    return;

    const style = window.getComputedStyle(
      this.widgets.get(id).location.nativeElement
    );
    const defaultStyle = this.defaultStylesSub.get(id).get('');
    if (style.display == 'grid') {
      let css;
      if (mode == 'off') {
        css = {};
        const a = this.transformtocss(
          id,
          this.theme,
          id.toString(),
          css,
          ':before'
        );
        this.createClassDefinition(id, a, this.styleSheet);
      }
      if (mode == 'lines') {
        const colwidths = this.getColWidthSum(style);
        const colwidth = colwidths[0];
        const colGap = Number(
          style.gridColumnGap.substr(0, style.gridColumnGap.length - 2)
        );
        console.log(colwidth);
        css = {
          'background-color': 'rgba(255, 0, 0, 0.5)',
          content: "''",
          position: 'absolute',
          height: '100%',
          width: colwidth - colGap + 'px',
          'z-index': '9999',
          'pointer-events': 'none',
        };
        const a = this.transformtocss(
          id,
          this.theme,
          id.toString(),
          css,
          ':before'
        );
        const cssid = '[is] .' + this.theme + '-' + id.toString() + '::before';
        console.log(cssid);
        console.log(this.styleSheet);
        this.removeClassBySelector(cssid);
        this.createClassDefinition(id, a,this.styleSheet);
      }
    }
  }

  transformtocss(id, theme, name, defs, pseudo?) {
    const css: string[] = [];
    let cssstr = '';
    for (const [key, value] of Object.entries(defs)) {
      cssstr = cssstr + key + ':' + value + ';';
    }
    css.push(
      '[is] .' +
        theme +
        '-' +
        id.toString() +
        pseudo +
        ' {' +
        cssstr +
        '}' +
        ' '
    );
    return css;
  }

  getColWidthSum(style) {
    let colGap;
    const colWidths = [];
    const colWidthsSum = [];
    if (style.display == 'grid') {
      colGap =
        style.gridColumnGap.toString() == 'normal'
          ? 0
          : style.gridColumnGap.toString();

      style.gridTemplateColumns.split(' ').forEach((col) => {
        if (colGap) {
          colWidths.push(
            Number(col.substr(0, col.length - 2)) +
              Number(colGap.substr(0, colGap.length - 2))
          );
        } else {
          colWidths.push(Number(col.substr(0, col.length - 2)));
        }
      });
      if (colGap)
        colWidths[colWidths.length - 1] -= Number(
          colGap.substr(0, colGap.length - 2)
        );

      colWidthsSum[0] = colWidths[0];
      for (let i = 1; i < colWidths.length; i++) {
        colWidthsSum[i] = colWidthsSum[i - 1] + colWidths[i];
      }
    }
    return colWidthsSum;
  }

  getRowHeightSum(style) {
    let rowGap;
    const rowHeights = [];
    const rowHeightsSum = [];
    if (style.display == 'grid') {
      rowGap =
        style.gridColumnGap.toString() == 'normal'
          ? 0
          : style.gridRowGap.toString();

      style.gridTemplateRows.split(' ').forEach((row) => {
        if (rowGap) {
          rowHeights.push(
            Number(row.substr(0, row.length - 2)) +
              Number(rowGap.substr(0, rowGap.length - 2))
          );
        } else {
          rowHeights.push(Number(row.substr(0, row.length - 2)));
        }
      });
      if (rowGap)
        rowHeights[rowHeights.length - 1] -= Number(
          rowGap.substr(0, rowGap.length - 2)
        );

      rowHeightsSum[0] = rowHeights[0];
      for (let i = 1; i < rowHeights.length; i++) {
        rowHeightsSum[i] = rowHeightsSum[i - 1] + rowHeights[i];
      }
    }
    return rowHeightsSum;
  }

  getStartCol(ev, colWidthsSum, parentinfo, cor = 0) {
    if (ev.clientX - parentinfo.left - cor < colWidthsSum[0] / 2) {
      return 1;
    } else {
      for (let i = 1; i < colWidthsSum.length; i++) {
        if (
          ev.clientX - parentinfo.left - cor <
          colWidthsSum[i - 1] + (colWidthsSum[i] - colWidthsSum[i - 1]) / 2
        ) {
          return i + 1;
        }
      }
    }
  }

  getEndCol(ev, colWidthsSum, parentinfo, cor = 0) {
    for (let i = 0; i < colWidthsSum.length; i++) {
      if (
        ev.clientX - parentinfo.left - cor <
        colWidthsSum[i] + (colWidthsSum[i + 1] - colWidthsSum[i]) / 2
      ) {
        return i + 2;
      }
    }
    return colWidthsSum.length + 1;
  }

  getStartRow(ev, rowHeightsSum, parentinfo, cor = 0) {
    if (ev.clientY - parentinfo.top - cor < rowHeightsSum[0] / 2) {
      return 1;
    } else {
      for (let i = 1; i < rowHeightsSum.length; i++) {
        if (
          ev.clientY - parentinfo.top - cor <
          rowHeightsSum[i - 1] + (rowHeightsSum[i] - rowHeightsSum[i - 1]) / 2
        ) {
          return i + 1;
        }
      }
    }
  }

  getEndRow(ev, rowHeightsSum, parentinfo, cor = 0) {
    for (let i = 0; i < rowHeightsSum.length; i++) {
      if (
        ev.clientY - parentinfo.top - cor <
        rowHeightsSum[i] + (rowHeightsSum[i + 1] - rowHeightsSum[i]) / 2
      ) {
        return i + 2;
      }
    }
    return rowHeightsSum.length + 1;
  }

  getColLength(style) {
    const colStart = style.gridColumnStart;
    const colEnd = style.gridColumnEnd;
    if (colEnd < 0) {
      return -1;
    }
    if (!colEnd) {
      return 1;
    } else {
      return colEnd - colStart;
    }
  }

  getRowLength(style) {
    const rowStart = style.gridRowStart;
    const rowEnd = style.gridRowEnd;
    if (rowEnd < 0) {
      return -1;
    }
    if (!rowEnd) {
      return 1;
    } else {
      return rowEnd - rowStart;
    }
  }

  gradientFromCols(colWidths) {
    const color = 'red';

    let string = 'linear-gradient(to right,';

    string += 'transparent 0px,';

    colWidths.forEach((col) => {
      string += 'transparent ' + (col - 1).toString() + 'px,';
      string += color + ' ' + (col - 1).toString() + 'px,';
      string += color + ' ' + col.toString() + 'px,';
      string += 'transparent ' + col.toString() + 'px,';
    });
    string += 'transparent 100%';
    string += ');';

    return string;
  }

  scaleGrid(style, scale) {
    const colWidths = [];
    style.gridTemplateColumns.split(' ').forEach((col) => {
      colWidths.push(Number(col.substr(0, col.length - 2)) * scale + 'px');
    });
    const gridTemplateColumns = colWidths.join(' ');

    const rowHeights = [];
    style.gridTemplateRows.split(' ').forEach((row) => {
      rowHeights.push(Number(row.substr(0, row.length - 2)) * scale + 'px');
    });
    const gridTemplateRows = rowHeights.join(' ');

    const gridColumnGap =
      style.gridColumnGap.toString() == 'normal'
        ? 'normal'
        : Number(
            style.gridColumnGap.substr(0, style.gridColumnGap.length - 2)
          ) *
            scale +
          'px';
    const gridRowGap =
      style.gridRowGap.toString() == 'normal'
        ? 'normal'
        : Number(style.gridRowGap.substr(0, style.gridRowGap.length - 2)) *
            scale +
          'px';

    return {
      ...style,
      gridTemplateRows: gridTemplateRows,
      gridTemplateColumns: gridTemplateColumns,
      gridRowGap: gridRowGap,
      gridColumnGap: gridColumnGap,
    };
  }

  splitterHandlers = [];

  resizableSplitters(el) {
    const splitterEventHandler = {
      handleEvent: (ev) => {
        ev.stopPropagation();
        this.moveFromSplitter(el, ev.detail);
      },
    };

    el.addEventListener('splitter-event', splitterEventHandler);

    // remove events
    this.splitterHandlers.push({
      eventname: 'splitter-event',
      element: el,
      handler: splitterEventHandler,
    });
  }

  removeSplitterHandlers() {
    for (const ev of this.splitterHandlers) {
      ev.element.removeEventListener(ev.eventname, ev.handler);
    }
    this.splitterHandlers = [];
  }

  moveFromSplitter(el, detail) {
    const parentStyle = window.getComputedStyle(el);
    if (parentStyle.display == 'grid') {
      for (let i = 0; i < el.children.length; i++) {
        const style = window.getComputedStyle(el.children[i]);
        const startCol = Number(style['grid-column-start']);
        const endCol = Number(style['grid-column-end']);
        const startRow = Number(style['grid-row-start']);
        const endRow = Number(style['grid-row-end']);
        const id = el.children[i].attributes['_isid']
          ? Number(el.children[i].attributes['_isid'].value)
          : 0;

        if (
          detail.direction == 'row' &&
          startCol >= detail.startCol &&
          endCol <= detail.endCol
        ) {
          // comp
          // splitter
          if (detail.oldStartRow == endRow - 1) {
            const diff = -(detail.oldStartRow - detail.startRow);
            if (diff != 0) {
              this.setDefaultStyleSub(id, 'grid-row-end', endRow + diff);
              this.removeClassDefinition(id);
              this.createWidgetStyleClass(id, this.styleSheet);
            }
          }

          // splitter
          // comp
          if (detail.oldStartRow == startRow - 1) {
            const diff = -(detail.oldStartRow - detail.startRow);
            if (diff != 0) {
              this.setDefaultStyleSub(id, 'grid-row-start', startRow + diff);
              // this.compservice.setDefaultStyleSub(id, 'grid-row-end', endRow + diff);
              this.removeClassDefinition(id);
              this.createWidgetStyleClass(id, this.styleSheet);
            }
          }
        }

        if (
          detail.direction == 'col' &&
          startRow >= detail.startRow &&
          endRow <= detail.endRow
        ) {
          // comp | splitter
          if (detail.oldStartCol == endCol) {
            const diff = -(detail.oldStartCol - detail.startCol);
            if (diff != 0) {
              this.setDefaultStyleSub(id, 'grid-column-end', endCol + diff);
              this.removeClassDefinition(id);
              this.createWidgetStyleClass(id, this.styleSheet);
            }
          }

          // splitter | comp
          if (detail.oldStartCol == startCol) {
            const diff = -(detail.oldStartCol - detail.startCol);
            if (diff != 0) {
              this.setDefaultStyleSub(id, 'grid-column-start', startCol + diff);
              this.removeClassDefinition(id);
              this.createWidgetStyleClass(id, this.styleSheet);
            }
          }
        }
      }
    }
  }

  deletewidget(id: number) {
    this.widgets.delete(id);
  }

  hideWidget(id: number) {
    const widget = this.widgets.get(id);
    widget?.location.nativeElement.setAttribute('hidden');
  }

  newFormGroup(
    { id, data, formFormGroup, saveFormGroups, formgroupdef }: IsFormGroup,
    load?: boolean
  ) {
    formFormGroup = new FormGroup({});

    if (formgroupdef) {
      formgroupdef.forEach((item) => {
        const control = this.formcontrols.get(item.controlname);
        if (control) {
          formFormGroup.addControl(item.controlname, control.formcontrol);
          if (item.twoway) {
            const c = this.formcontrols.get(item.key);
            if (c) {
              this.formcontrols.set(item.controlname, c);
              const path = item.controlname.split('.');
              const grid = this.gci(parseInt(path[0]));
              grid.view.gridFormGroup.removeControl(path[1]);
              grid.view.gridFormGroup.addControl(path[1], c.formcontrol);
            } else {
              console.error(
                'newformgroup - twoway ',
                id,
                ' ',
                item.key,
                ' does not exists on this form'
              );
            }
          }

          if (data && control) {
            control.formcontrol.setValue(data[item.key]);
          }
          /* isgrid fills normal controls on form level set inital value */
          const controlunique = this.formcontrols.get(item.key);
          if (controlunique) {
            //controlunique.formcontrol.valueChanges.subscribe(params => {
            //  console.log('control unique : ',params)
            //});

            if (control['master']) {
              if (controlunique.formcontrol && control.formcontrol) {
                controlunique.formcontrol.setValue(control.formcontrol.value);
              }
            }
          }
        } else {
          console.error(
            'isform.newformgroup - formcontrol ',
            id,
            ' ',
            item.controlname,
            ' does not exists on this form'
          );
        }

        const interfacecontrol = this.formcontrols.get(item.key);
        if (interfacecontrol) {
          const datagroup = item.controlname.split('.')[0];
          let sgroup = saveFormGroups.find((item) => item.name == datagroup);
          if (!sgroup) {
            sgroup = {
              name: datagroup,
              formgroup: new FormGroup({}),
              oldvalue: null,
              newvalues: [],
            };
            saveFormGroups.push(sgroup);
          }
          sgroup?.formgroup.addControl(item.key, interfacecontrol.formcontrol);
        }
      });

      if (!load) {
        this.store.dispatch({
          type: '[UpdateWidgetPath]',
          payload: {
            id: id,
            path: ['config', 'formgroupdef'],
            value: formgroupdef,
          },
        });
      }

      formFormGroup.valueChanges.pipe(debounceTime(250)).subscribe((params) => {
        //console.log('form params changes', params);

        // save values to the datasets
        saveFormGroups.forEach((item) => {
          const itemlist = this.gci(parseInt(item.name));

          if (item.formgroup.dirty) {
            if (item.formgroup.valid) {
              //console.log(' save form group dirty : ', item);

              const newvalue = { ...item.oldvalue };

              if (itemlist.type == 'isgrid') {
                for (const [key, value] of Object.entries(
                  item.formgroup.controls
                )) {
                  const gridfield = formgroupdef.find((item) => {
                    return item.key == key;
                  });
                  const fieldname = gridfield.controlname.split('.')[1];
                  const gridcontrol = this.formcontrols.get(
                    gridfield.controlname
                  );
                  if (gridcontrol) {
                    if (value.dirty) {
                      if (value.value instanceof Date) {
                        newvalue[fieldname] =
                          value.value.getMonth() +
                          1 +
                          '/' +
                          value.value.getDate() +
                          '/' +
                          value.value.getFullYear();
                      } else {
                        newvalue[fieldname] = value.value;
                      }
                    }
                  }
                }
              }

              if (!_.isEqual(item.oldvalue, newvalue)  && item.oldvalue) {
                //console.log(' save adapted record : old : ', item.oldvalue, 'new : ', newvalue);
                const idx = itemlist.view.data.findIndex((item) => {
                  return item[itemlist.keyfield] == newvalue[itemlist.keyfield];
                });

                item.newvalues.push({
                  timestamp: new Date(),
                  key: itemlist.keyfield,
                  oldvalue: { ...item.oldvalue },
                  newvalue: { ...newvalue },
                });

                if (item.oldvalue) {
                  Object.entries(item.oldvalue).forEach(([k, v]) => {
                    item.oldvalue[k] = newvalue[k];
                  });
                }
                //itemlist.view.data.splice(idx, 1, newvalue);

                const updatevalue = itemlist.removenonupdatefields(newvalue);

                if (itemlist.updatecommand) {
                  this.store.dispatch({
                    type: '[Execute]',
                    payload: {
                      command: itemlist.updatecommand,
                      data: updatevalue,
                    },
                  });
                }

                if (itemlist.view['selectedcards']) {
                  const compRef = this.gcr(item.oldvalue?.cardid);
                  compRef?.changeDetectorRef.detectChanges();
                } else {
                  if (itemlist.view.selectedRecord) {
                    itemlist.cd.detectChanges();
                  }
                }
              }
            }
          }
          if (itemlist?.view) {
            if (itemlist.view['selectedcards']?.size>0) {
              item.oldvalue = itemlist.view['selectedcards']
                ?.entries()
                ?.next()?.value[1]?.data;
            } else {
              if (itemlist.view.selectedRecord) {
                item.oldvalue = itemlist.view.selectedRecord;
              }
            }
          }
        });

        formgroupdef.forEach((item) => {
          if (!item.twoway) {
            const control = this.formcontrols.get(item.key);
            if (control) {
              control.formcontrol.setValue(params[item.controlname], {
                onlySelf: true,
              });
            }
          }
        });
      });
    }
  }
}
